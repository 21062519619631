<template>
  <div class="article-box jcxx">
    <h3 style="margin-bottom: 15px;">论坛报名</h3>

    <div style="text-align: center; font-size: 16px;color: #000; font-weight: 700; margin-top: 20px;;">{{$route.query.title}}</div>
    <el-form ref="addForm" :model="addForm" :rules="rules" label-width="100px" class="demo-ruleForm" style="width: 500px; margin: 30px auto;">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="addForm.name"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio-group v-model="addForm.sex">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="addForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="民族" prop="national">
        <el-input v-model="addForm.national"></el-input>
      </el-form-item>
      <el-form-item label="单位" prop="unit">
        <el-input v-model="addForm.unit"></el-input>
      </el-form-item>
      <el-form-item label="职务" prop="position">
        <el-input v-model="addForm.position"></el-input>
      </el-form-item>
      <el-form-item label="是否住宿" prop="zs">
        <el-radio-group v-model="addForm.zs">
          <el-radio :label="1">需要</el-radio>
          <el-radio :label="2">不需要</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="房间类型" prop="roomType" v-if="addForm.zs == 1">
        <el-radio-group v-model="addForm.roomType">
          <el-radio :label="1">标间</el-radio>
          <el-radio :label="2">大床房</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="住宿日期" prop="date" v-if="addForm.zs == 1">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="addForm.date"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div style="text-align: center;">
      <el-button type="primary" @click="submit">提交报名</el-button>
    </div>
  </div>
</template>

<script>
import { forumAdd } from '@/http/api/ArticleApi'
export default {
  data () {
    return {
      addForm: {
        name: '',
        sex: '',
        phone: '',
        national: '',
        unit: '',
        position: '',
        zs: '',
        roomType: '',
        date: []
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式错误' }
        ],
        national: [{ required: true, message: '请输入民族', trigger: 'blur' }],
        unit: [{ required: true, message: '请输入单位', trigger: 'blur' }],
        position: [{ required: true, message: '请输入职务', trigger: 'blur' }],
        zs: [{ required: true, message: '请选择是否住宿', trigger: 'change' }],
        roomType: [{ required: true, message: '请选择房间类型', trigger: 'change' }],
        date: [{ required: true, message: '请选择住宿时间', trigger: 'change' }]

      }
    }
  },
  methods: {
    submit () {
      this.$refs.addForm.validate(valid => {
        if (!valid) return false
        const parmas = {
          cmsId: this.$route.query.id,
          name: this.addForm.name,
          sex: this.addForm.sex,
          phone: this.addForm.phone,
          national: this.addForm.national,
          unit: this.addForm.unit,
          position: this.addForm.position,
          beginTime: '',
          endTime: '',
          roomType: this.addForm.roomType
        }
        if (this.addForm.zs == 1) {
          parmas.beginTime = this.addForm.date[0]
          parmas.endTime = this.addForm.date[1]
        }
        forumAdd(parmas).then(res => {
          if(res.code == '000') {
            this.$message.success('提交成功')
            this.$refs.addForm.resetFields()
          }
        })
      })
    }
  }
}
</script>
